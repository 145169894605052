import bbblogo from "../../Assets/BBBAccredited.jpg";
import googleIcon from "../../Assets/google_g_icon.png";
import iconInstagram from "../../Assets/iconInstagram.svg";
import { BsTelephoneForwardFill } from "react-icons/bs";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { Autoplay } from "swiper";

import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="phoneNumberFooter">
        <a href="tel:604-467-4417" className="phoneCall">
          <BsTelephoneForwardFill className="phoneIcon" />{" "}
          <span href=""> 604-467-4417 </span>
        </a>
      </div>

      <h3 className="locationHeader">
        {" "}
        We serve areas across the Lower Mainland:{" "}
      </h3>
      <Swiper
        className="cityAreasSlider"
        spaceBetween={10}
        slidesPerView={3}
        slidesPerGroup={1}
        loop={true}
        autoplay={{
          delay: 1000,
          disabledOnInteraction: false,
        }}
        modules={[Autoplay]}
        breakpoints={{
          320: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          1100: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
        }}
      >
        <SwiperSlide className="city">Maple Ridge</SwiperSlide>
        <SwiperSlide className="city">Coquitlam</SwiperSlide>
        <SwiperSlide className="city"> Abbotsford</SwiperSlide>
        <SwiperSlide className="city">Chilliwack</SwiperSlide>
        <SwiperSlide className="city"> Mission</SwiperSlide>
        <SwiperSlide className="city">Vancouver</SwiperSlide>
        <SwiperSlide className="city">Langley</SwiperSlide>
        <SwiperSlide className="city">Pitt Meadows</SwiperSlide>
        <SwiperSlide className="city">Surrey</SwiperSlide>
        <SwiperSlide className="city">Burnaby</SwiperSlide>
        <SwiperSlide className="city">Port Coquitlam</SwiperSlide>
      </Swiper>
      <div className="footContainerWrapper">
        <div className="footIconContainer">
          <p className="iconText"> Leave us a review: </p>
          <div className="iconWrapper">
            <a
              href="https://www.google.com/maps/place/Primeline+Industries/@49.2304064,-122.5248591,14z/data=!4m7!3m6!1s0x0:0xb270a42f28aba5ae!8m2!3d49.2347002!4d-122.5362208!9m1!1b1"
              target="_blank"
              rel="noreferrer"
            >
              <img src={googleIcon} alt="" className="googleIcon" />
            </a>
          </div>
        </div>
        <div className="footIconContainer">
          <p className="iconText"> Check us out: </p>
          <div className="iconWrapper">
            <a
              href="https://www.instagram.com/patiocovers/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={iconInstagram} alt="" className="footerInstaIcon" />
            </a>
          </div>
        </div>
        <div className="footIconContainer">
          <p className="iconText bbbContainer">
            {" "}
            <span>BBB Rating:</span> <span className="rating"> A+</span>{" "}
          </p>
          <div className="iconWrapper">
            <a
              href="https://www.bbb.org/ca/bc/maple-ridge/profile/awnings/primeline-industries-0037-172026"
              target="_blank"
              rel="noreferrer"
            >
              <img src={bbblogo} alt="" className="bbblogo" />
            </a>
          </div>
        </div>
      </div>
      <small>
        Copyright &copy; 2023, Primeline Industries. All rights reserved.{" "}
      </small>
    </div>
  );
};

export default Footer;
